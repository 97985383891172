import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import MenuVid from "../../../../src/vid/PeepShowMainMenu.mp4";
import React from 'react';
export default {
  FontAwesomeIcon,
  faCamera,
  faVideo,
  MenuVid,
  React
};