import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import RendererVideoOgv from "../../../../src/vid/Intro3dDemo.ogv";
import RendererVideoMp4 from "../../../../src/vid/Intro3dDemo.mp4";
import RendererVideoWebm from "../../../../src/vid/Intro3dDemo.webm";
import React from 'react';
export default {
  FontAwesomeIcon,
  faCamera,
  faVideo,
  RendererVideoOgv,
  RendererVideoMp4,
  RendererVideoWebm,
  React
};