import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faVideo } from '@fortawesome/free-solid-svg-icons';
import HousewrkVideo from "../../../../src/vid/housewrk.mp4";
import React from 'react';
export default {
  FontAwesomeIcon,
  faCamera,
  faVideo,
  HousewrkVideo,
  React
};